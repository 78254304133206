import * as React from "react"
import Layout from "../components/layout"
import MainBlock from "../components/cms/main";
import NewsArchive from "../components/cms/news-archive";
import Sitemap from "../components/cms/sitemap";
import coverImage from "../images/headers/header_nieuws.webp"
import page from "../data/pages/nl.nieuws";
import {graphql} from "gatsby";
import Helmet from "../components/helmet";

const NewsPage = ({data}) => {

    return (
        <Layout>
            <Helmet meta={page.pageData.meta} />
            <MainBlock id={"mainCover"} cover={coverImage} type={"sub"} jumbotron={page.pageData.jumbotron} bpx={35} bpy={60}/>
            <div className={'container f-wrap pt-25 white'}>
                <div className={'f-container f-wrap p-20'}>
                    { data.allStrapiArticles && data.allStrapiArticles.nodes.map((n, i) => {
                        return <NewsArchive item={n} />
                    }) }
                </div>
            </div>
            <Sitemap/>
        </Layout>
    )
}

export const postQuery = graphql`
  query AllNews {
      allStrapiArticles(sort: {fields: Date, order: DESC}) {
        nodes {
          Title
          Content
          Slug
          Date
          Image {
            id
            url
          }
        }
      }
    }
`

export default NewsPage
