export default {
    pageData: {
        meta: {
            title: "Wij kennen de kracht van informatie",
            description: "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor."
        },
        jumbotron: {
            title: "Nieuws",
            subtitle: "",
            callToAction: "",
            callToActionLink: "",
        }
    },
}
