import { Link } from "gatsby"
import React, { Component } from "react"
import he from 'he';
import moment from 'moment';
import 'moment/locale/nl';

class NewsArchive extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const date = moment(this.props.item.Date, 'YYYY-MM-DD');
        const intro = he.decode((this.props.item.Content)).replace(/(<([^>]+)>)/gi, "")
        const title = he.decode((this.props.item.Title)).replace(/(<([^>]+)>)/gi, "")

        return (
            <>
                <div className={'col-4'} style={{flex:'1 1 450px'}}>
                    <div className={'f-container'}>
                        <div className={'full-height'}>
                            <img width={240} height={120} alt={this.props.item.Title.replace(/[^a-z0-9\s-]/ig, '')} className={'w-auto h-120 mw-150 mh-120'} style={{
                                objectFit: 'contain'
                            }} src={`${process.env.IMAGE_BASE_URL}${this.props.item.Image.url}`} />
                        </div>

                        <div className={'p-10 overflow-hidden f-container f-column f-justify-center'}>
                            <div className={'bold lh-100 pb-5'}>{title}</div>
                            <div className={'lh-100 pb-5'}>{date.locale('nl').format('LL')}</div>
                            <div className={'lc-2 lh-100'} dangerouslySetInnerHTML={{ __html: intro }}/>
                            <div className={'pt-5'}>
                                {<Link to={`/nieuws/${this.props.item.Slug}`} className={'blue-button-small'}>Lees meer
                                </Link>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default NewsArchive
